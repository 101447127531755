import React from 'react';
import { graphql } from "gatsby";
import Img from 'gatsby-image';
import Layout from '../components/layout';

export default ({data}) => {
  return(
    <Layout>
      <div className="page404">
        <h1 className="page404Header font-f5 ">Page not found</h1>
        <p className="page404txt font-more">The page you were trying to get to does not exist,! it is probably our fault but just in case, please check that the URL you entered is correct.,</p>
        <div className="img404wrapper">
          <Img fluid={data.img404.childImageSharp.fluid} className="img404"/>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    img404: file(relativePath: { eq: "img404.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;